.container {
    padding: 20px;
    line-height: 1.6;
    background-color: #f9f9f9;
    color: #333;
    font-family: Arial, sans-serif;
}

.heading {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #1a1a1a;
    text-align: center;
}

.effectiveDate {
    font-style: italic;
    margin-bottom: 2rem;
    color: #666;
    text-align: center;
}

.sectionHeading {
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    color: #1a1a1a;
}

.text {
    margin-bottom: 1rem;
    color: #333;
}

.list {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 1rem;
    color: #333;
}

.list li {
    margin-bottom: 0.5rem;
}
