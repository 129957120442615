.footer {
    width: 100%;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    text-align: center;
    position: absolute;
    left: 0;
}

.footer nav {
    margin-top: 10px;
}

.footer a {
    color: #fff;
    margin: 0 10px;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}
